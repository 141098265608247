.modal-content-values {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.modal-controls-values {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0px 10px;
}