/* ========================================
   Accordion Group 
======================================== */
.accordion-group {
    border-radius: 8px;
    overflow: hidden;
}

.accordion-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    font-weight: semi-bold;
}

.accordion-group-section {
    border-bottom: 1px solid var(--secondary-color);
}

.accordion-group-section:last-child {
    border-bottom: none;
}

.accordion-group-section.open .accordion-content {
    max-height: 40vh !important;
}

.accordion-group-section.open .accordion-header {
    background-color: var(--box-shadow-hover-color);
}

/* ========================================
   Accordion Header 
======================================== */
.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 8px;
}

.accordion-header:hover {
    background-color: var(--box-shadow-hover-color);
}

.accordion-header:hover .accordion-icon,
.accordion-header:focus-within .accordion-icon {
    color: var(--primary-color);
}

/* ========================================
   Accordion Title Area 
======================================== */
.accordion-title-area {
    display: flex;
    align-items: center;
}

.accordion-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0;
}

/* ========================================
   Accordion Icon 
======================================== */
.accordion-icon {
    margin-right: 8px;
    color: var(--secondary-color);
    transition: color 0.3s ease;
}

/* ========================================
   Accordion Content 
======================================== */
.accordion-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: scroll;
}

.accordion-content p {
    margin: 0;
    color: var(--secondary-color);
    font-size: 14px;
}

