:root {
  /* Light Theme Variables */
  --primary-color-light: #424242;
  --secondary-color-light: #8D8D8D;
  --background-color-light: #EAEAEA;
  --navbar-background-light: #F5F5F5;
  --overlay-color-light: rgba(255, 255, 255, 0.8);

  --edit-icon-light: green;
  --download-icon-light: blue;
  --delete-icon-light: red;

  --box-shadow-color-light: rgba(0, 0, 0, 0.12);
  --box-shadow-hover-color-light: rgba(0, 0, 0, 0.2);

  --gradient-light: linear-gradient(90deg, #2B2D42 0%, #3A3F58 50%, #4C5C76 100%);

  /* Dark Theme Variables */
  --primary-color-dark: #E0E0E0;              /* Light gray for primary text */
  --secondary-color-dark: #B0B0B0;            /* Medium gray for secondary text */
  --background-color-dark: #1A1D21;           /* Dark gray background */
  --navbar-background-dark: #121518;          /* Slightly darker navbar background */
  --overlay-color-dark: rgba(26, 29, 33, 0.85); /* Dark overlay */

  --edit-icon-dark: #81C784;                  /* Soft green for edit icons */
  --download-icon-dark: #64B5F6;              /* Soft blue for download icons */
  --delete-icon-dark: #E57373;                /* Soft red for delete icons */

  --box-shadow-color-dark: rgba(255, 255, 255, 0.15);           /* Subtle shadow for dark mode */
  --box-shadow-hover-color-dark: rgba(255, 255, 255, 0.25);     /* Slightly stronger shadow on hover */

  --gradient-dark: linear-gradient(90deg, #FDFBFB 0%, #ECE9E6 50%, #D9D7D5 100%);
}

[data-theme="light"] {
  --primary-color: var(--primary-color-light);
  --secondary-color: var(--secondary-color-light);
  --background-color: var(--background-color-light);
  --navbar-background: var(--navbar-background-light);
  --overlay-color: var(--overlay-color-light);

  --edit-icon: var(--edit-icon-light);
  --download-icon: var(--download-icon-light);
  --delete-icon: var(--delete-icon-light);

  --box-shadow-color: var(--box-shadow-color-light);
  --box-shadow-hover-color: var(--box-shadow-hover-color-light);

  --gradient: var(--gradient-light);
}

[data-theme="dark"] {
  --primary-color: var(--primary-color-dark);
  --secondary-color: var(--secondary-color-dark);
  --background-color: var(--background-color-dark);
  --navbar-background: var(--navbar-background-dark);
  --overlay-color: var(--overlay-color-dark);

  --edit-icon: var(--edit-icon-dark);
  --download-icon: var(--download-icon-dark);
  --delete-icon: var(--delete-icon-dark);

  --box-shadow-color: var(--box-shadow-color-dark);
  --box-shadow-hover-color: var(--box-shadow-hover-color-dark);

  --gradient: var(--gradient-dark);
}
