@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.theme-changing * {
    transition: background-color 0.5s ease, color 0.3s ease, border 0.3s ease !important;
}  

body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--background-color);
    overflow-y: hidden;
}
  
.main-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
  
.content-container {
  display: flex;
  flex-direction: row;
  height: calc(92.5% - var(--description-match-height));
  max-height: calc(92.5% - var(--description-match-height));
  overflow-y: auto;
  transition: width 0.3s ease, flex 0.5s ease; /* Smooth transition when notification center collapses */
}  
  
.frameworks-container,
.table-container,
.notification-container {
  padding: 20px;
  background-color: var(--background-color);
}
  
.frameworks-container,
.notification-container {
  width: 15%;
  overflow-y: auto;
  position: relative;
  transition: width 0.5s ease-in; /* Adds a transition effect for the width */
}

.frameworks-container.open {
  width: 30%;
}

  
.table-container {
  flex: 1;
  overflow-x: auto;
  padding: 20px;
  position: relative;
  transition: flex 0.5s ease, width 0.5s ease; /* Smooth transition for table resizing */
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.error-container h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary-color);
  margin-top: 15px;
}

.error-container p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px; /* Slightly adjusted margin */
  color: var(--secondary-color);
}