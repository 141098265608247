.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
}
  
.login-card {
    width: 70vw;
    padding: 20px;
    background-color: var(--navbar-background);
    box-shadow: 0 2px 4px var(--box-shadow-color);
    border-radius: 6px;
}
  
.login-title {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}
  
.login-group {
    margin-bottom: 15px;
}
  
label {
    color: var(--primary-color);
    font-weight: bold;
}
  
.login-input {
    width: 100%;
    padding: 8px 16px;
    border: 2px solid var(--secondary-color);
    border-radius: 6px;
    margin-top: 5px;
}
  
.login-button {
    color: var(--secondary-color);
    background: none;
    border: 2px solid var(--secondary-color);
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    gap: 8px;
    transition: all 0.3s ease, border-color 0.3s ease; /* Separate transition for border color */
}
  
.login-button:hover {
    background-color: var(--primary-color);
    color: var(--navbar-background);
}