/* src/styles/global/modal.css */

/* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: scroll;
  margin-bottom: 16px;
}

/* Modal content */
.modal-content {
  width: 30%;
  max-height: 90vh;
  max-height: 90dvh;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 0 10px var(--box-shadow-color);
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  overflow-y: auto;
}

/* Fade-in and fade-out for content */
.modal-content.fade-in {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.modal-content.fade-out {
  opacity: 0;
  visibility: visible;
  transform: scale(0.9);
} 

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 16px;
  position: sticky;
  top: 0px;
  z-index: 1000;
}

/* Close Button */
.modal-close-button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--background-color);
  font-size: 1.2em;
}

/* Content body */
.modal-content-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  font-size: 15px;
  color: var(--primary-color);
}

/* Checkbox container */
.checkbox-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

/* Checkbox label */
.checkbox-label {
  cursor: pointer;
  color: var(--secondary-color);
  margin-bottom: 16px;
  align-self: flex-start;
}

/* Checkbox button */
.checkbox-button {
  background: none;
  border: none;
  cursor: pointer;
  align-self: flex-start;
}

/* Checked State */
.checked {
  color: var(--secondary-color);
}

/* Table Container */
.data-table-container {
  flex: 3;
  max-height: 60vh;
  overflow-y: auto;
  padding-bottom: 16px;
}

.checkbox-cell,
.select-cell {
  display: flex;
  align-items: center;
}

.select-wrapper {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-wrapper .dropdown-container {
  flex-grow: 1;
}

/* Form Container */
.form-container {
  flex: 1;
  padding: 15px;
  background-color: var(--light-background-color);
  border-radius: 0 0 10px 10px;
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

/* Data Table */
.data-table {
  width: 100%;
}

.table-label {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Inputs */
.input-container {
  display: flex;
  align-items: center;
  gap: 5px; 
}

.input-label {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline;
}

.input-field {
  display: inline;
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 6px;
  font-size: 1em;
}

/* Buttons */
.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.form-action-button {
  flex-grow: 2;
  color: var(--primary-color);
  background: none;
  border: 2px solid var(--primary-color);
  padding: 8px 16px;
  border-radius: 6px;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease, border-color 0.3s ease;
  text-align: center;
}

.form-action-button:hover,
.form-action-button:active,
.form-action-button:focus {
  background-color: var(--primary-color);
  color: var(--background-color);
}

/* Loader and message overlay */
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay is on top */
}

.loader-overlay p {
  margin-top: 10px;
  font-size: 1.2em;
  color: var(--primary-color);
}
