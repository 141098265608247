.framework-table {
    height: 90%;
    width: 100%;
    border-collapse: collapse;
    background-color: var(--navbar-background);
    box-shadow: 0 2px 4px var(--box-shadow-hover-color);  /* Adapted shadow for dark mode */
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--navbar-background);
}

.framework-table th,
.framework-table td {
    padding: 12px 20px;
    text-align: left;
    border-bottom: 1px solid var(--secondary-color);
}

.framework-table th {
    background-color: var(--background-color);  /* A different shade for header background */
    color: var(--primary-color);  /* Changed to primary-color for visibility */
    position: sticky;
    top: 0;
    z-index: 2; /* Increase the z-index to ensure headers stay on top of the navbar */
}

.framework-table td {
    position: relative;
    max-height: 80px;
    color: var(--primary-color); /* Changed to primary-color for visibility */
}

.framework-table .scrollable-content {
    max-height: 80px;
    overflow-y: auto;
}

.framework-table tr:last-child td {
    border-bottom: none;
}

.framework-table th:first-child,
.framework-table td:first-child {
    border-top-left-radius: 4px;
}

.framework-table th:last-child,
.framework-table td:last-child {
    border-top-right-radius: 4px;
}

.no-data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 10px;
    color: var(--secondary-color);
}

.no-data-wrapper p {
    margin-top: 10px;
    font-size: 18px;
    color: var(--secondary-color);
}

.framework-table tr {
    transition: background-color 0.3s ease; /* Smooth transition for background color and slight movement */
}

.framework-table tr:not(.data-table-header-row):hover,
.framework-table tr:not(.data-table-header-row):focus-visible {
    background-color: var(--background-color); /* Highlight color when hovered or focused */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

/* Moved from FrameworkTable.css */
.table-filter-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--background-color);
}
  
.table-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background-color: var(--background-color);
    padding: 5px;  /* Reduced padding */
    border-radius: 5px;
}
  
.table-filter input, .table-filter datalist {
    flex: 1;
    margin-right: 5px; /* Reduced margin */
    padding: 3px 5px;  /* Reduced padding */
    border: 1px solid var(--secondary-color);
    background-color: var(--navbar-background);
    border-radius: 5px;
    font-size: 16px;  /* Reduced font size */
    color: var(--primary-color);
}
  
.table-filter button {
    background-color: var(--navbar-background);
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    margin-right: 5px; /* Reduced margin */
    padding: 3px 5px;  /* Reduced padding */
    font-size: 16px;  /* Reduced font size */
    cursor: pointer;
    text-align: left;
    color: var(--primary-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.table-filter button:hover {
    background-color: var(--primary-color);
    color: var(--navbar-background);
}

.table-filter button:focus-visible {
    background-color: var(--primary-color);
    color: var(--navbar-background);
    outline: none; 
}

.table-filter button:focus {
    outline: none;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.pagination button {
    padding: 5px 10px;
    border: 1px solid var(--secondary-color);
    background-color: var(--navbar-background);
    color: var(--primary-color);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination span {
    font-size: 16px;
    color: var(--primary-color);
}

.pagination button:not(:disabled):hover, .pagination button:not(:disabled):focus-visible {
    background-color: var(--primary-color);
    color: var(--navbar-background);
}