/* ========================================
   Framework List Container
======================================== */
.framework-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background-color: var(--navbar-background);
  box-shadow: 0 2px 4px var(--box-shadow-hover-color);
  border-radius: 4px;
  overflow: hidden;
}

/* ========================================
   Loading Frameworks
======================================== */
.loading-frameworks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--secondary-color);
}

.loading-frameworks p {
  margin-top: 16px; /* Space between spinner and text */
  font-size: 16px;
  color: var(--secondary-color);
}

/* ========================================
   Icon Button
======================================== */
.icon-button {
  color: var(--secondary-color);
  background: none;
  border: none;
  padding: 2px;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.icon-button:hover,
.icon-button:active,
.icon-button:focus-visible {
  background-color: var(--primary-color);
  color: var(--navbar-background);
}

.plus-icon {
  font-size: 20px;
}

/* ========================================
   Search Bar
======================================== */
.search-bar {
  position: relative;
  margin-bottom: 8px;
}

.search-bar input {
  width: 100%;
  padding: 4px 24px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  background-color: transparent;
  color: var(--secondary-color);
}

.search-bar input::placeholder {
  color: var(--secondary-color);
  font-size: 14px;
}

.search-bar input:focus {
  outline: none;
  border-bottom: 1px solid var(--primary-color);
}

.search-bar .search-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-color);
  font-size: 16px;
}

.search-bar .clear-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-color);
  font-size: 16px;
  cursor: pointer;
}

.search-bar .clear-icon:hover {
  color: var(--primary-color);
}

/* ========================================
   Framework List Body
======================================== */
.framework-list .framework-list-body {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 5px; /* Add padding for scrollbar */
}

/* ========================================
   Group Header
======================================== */
.group-header {
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin-top: 10px;
  color: var(--secondary-color);
  font-size: 14px;
  transition: color 0.3s ease, font-weight 0.3s ease;
}

/* Action Button in Group Header */
.group-action-button {
  align-self: self-end;
  background: none;
  border: none;
  color: var(--secondary-color);
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  margin-left: auto; /* Pushes the group name and toggle to the right */
}

.group-action-button:hover {
  color: var(--primary-color);
}

/* Right Side of Group Header */
.group-header-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: auto; /* Pushes the content to the left */
}

.group-header-right:hover .group-name {
  color: var(--primary-color);
  font-weight: bold;
}

.group-header-right:hover .group-toggle {
  color: var(--primary-color);
}

.group-header-right .group-name {
  font-size: 14px;
  margin-left: 5px;
  text-transform: capitalize;
}

.group-header-right .group-toggle {
  font-size: 14px;
}

/* Active State */
.group-header.active .group-name {
  color: var(--primary-color);
  font-weight: bold;
}

.group-header.active .group-toggle {
  color: var(--primary-color);
}

/* ========================================
   Grouped Frameworks
======================================== */
.framework-list ul,
.framework-group ul {
  list-style-type: none;
  padding-left: 20px; /* Indentation */
  margin: 5px 0 0 0;
}

.framework-list.ungroup ul {
  padding-left: 0;
}

.framework-group li {
  margin: 5px 0;
}

/* ========================================
   Framework Button
======================================== */
.framework-button {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 6px 10px; /* Adjusted padding */
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  color: var(--secondary-color);
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.framework-item:hover .framework-button {
  color: var(--primary-color);
  font-weight: bold;
}

.framework-button.active {
  color: var(--primary-color);
  background-color: var(--box-shadow-hover-color); /* Slightly darker background for active */
  font-weight: bold;
}

/* ========================================
   Scrollbar Styling
======================================== */
/* Custom scrollbar for WebKit browsers */
.framework-list .framework-list-body::-webkit-scrollbar {
  width: 6px;
}

.framework-list .framework-list-body::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 3px;
}

.framework-list .framework-list-body::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}

.framework-list .framework-list-body::-webkit-scrollbar-track {
  background: transparent;
}

/* ========================================
   Item Buttons
======================================== */
.framework-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  color: var(--secondary-color);
  font-size: 14px;
  transition: all 0.3s ease; /* Smooth transitions for layout changes */
}

.framework-item .action-links {
  font-size: smaller;
  opacity: 0;
  width: 0;
  display: flex;
  gap: 8px;
  overflow: hidden;
  flex-grow: 1; /* Allow action-links to grow to fit its content */
  justify-content: flex-end; /* Align buttons to the right */
  transition: opacity 0.5s ease; /* Smoothly adjust opacity and width */
}

.framework-item:hover .action-links {
  opacity: 0.7;
  width: 100%; /* Expand to full width to fit all buttons */
}

.link-button {
  background: none;
  color: var(--secondary-color);
  border: none;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  white-space: nowrap; /* Prevent text wrapping */
}

.framework-item:hover .link-button {
  color: var(--primary-color);
}

.link-button:hover {
  text-decoration: underline;
}

/* Ensure text and buttons layout naturally without overlap */
.framework-item .framework-name {
  flex-grow: 1; /* Ensure name takes available space */
  overflow: hidden;
  text-overflow: ellipsis; /* Truncate if too long */
  white-space: nowrap;
}


/* ========================================
   New Category Section
======================================== */
.new-category-section {
  display: flex;
  align-items: center;
  gap: 8px;
  position: sticky;
  bottom: 0;
  background-color: var(--navbar-background);
  padding: 10px 0;
  margin-top: auto;
}

/* Input field styling, similar to search-bar input */
.new-category-section input[type="text"] {
  flex-grow: 1;
  padding: 4px 8px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  background-color: transparent;
  color: var(--secondary-color);
  transition: border-color 0.3s ease;
}

.new-category-section input[type="text"]::placeholder {
  color: var(--secondary-color);
  font-size: 14px;
}

.new-category-section input[type="text"]:focus {
  outline: none;
  border-bottom: 1px solid var(--primary-color);
}

/* Button styling consistent with icon-button and link-button */
.new-category-section button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.new-category-section button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.new-category-section button:hover:not(:disabled),
.new-category-section button:focus-visible:not(:disabled) {
  background-color: var(--primary-color);
  color: var(--navbar-background);
  border-color: var(--primary-color);
}
