/* TaskPage.css */
body {
  font-family: 'Open Sans', sans-serif;
}

.task-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.task-page-container {
  display: flex;
  flex-direction: row;
  height: calc(92.5% - var(--description-match-height));
  overflow-y: auto;
  transition: width 0.3s ease, flex 0.5s ease;
}

.notification-container {
  padding: 20px;
  background-color: var(--background-color);
  width: 15%;
  overflow-y: auto;
  position: relative;
}

.task-list {
  padding: 20px;
  background-color: var(--background-color);
  width: 15%;
  overflow-y: auto;
  position: relative;
}

.task-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.task-page-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  overflow-x: auto;
  padding: 20px;
  position: relative;
  transition: flex 0.5s ease, width 0.5s ease;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.error-container {
  text-align: center;
  padding: 50px;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .task-page-container {
    flex-direction: column;
  }

  .task-list,
  .notification-container {
    width: 100%;
    order: -1;
  }
}

.editable-field {
  border: 1px dashed var(--secondary-color);
}

.hidden {
  display: none;
}
