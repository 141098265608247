.notification-center {
  height: 100%;
  padding: 20px;
  background-color: var(--navbar-background);
  box-shadow: 0 4px 10px var(--box-shadow-color); /* Softer shadow for a subtle effect */
  overflow-y: auto;
  border-radius: 8px; /* Larger radius for smoother corners */
  display: flex;
  flex-direction: column;
}

.notification-center.slide-out {
  animation: slide-out 0.3s ease forwards;
}

.notification-center.slide-in {
  animation: slide-in 0.3s ease forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-color);
  margin-bottom: 12px; /* Adds some breathing room */
}

.notification-header h3 {
  font-size: 20px; /* Slightly larger for a modern look */
  font-weight: 600;
  color: var(--primary-color); /* Matches the primary color for consistency */
  margin: 0;
}

.collapse-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--secondary-color);
  font-size: 18px; /* Slightly larger icon size */
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.collapse-btn:hover {
  color: var(--primary-color);
}

.notification-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 5px; /* Prevents scrollbar overlap */
  margin-top: 10px;
}

.notification-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  padding: 10px 16px;
  margin-bottom: 14px;
  margin-top: 2px;
  background-color: var(--background-color);
  border-radius: 8px; /* Larger, rounded corners */
  border-left: 4px solid var(--primary-color);
  box-shadow: 0 4px 6px var(--box-shadow-color); /* Softer shadow */
  transition: transform 0.2s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.notification-item:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 6px 12px var(--box-shadow-hover-color); /* Elevate the shadow */
}

.notification-details {
  display: flex;
  flex-direction: column;
}

.notification-message {
  font-size: 16px;
  color: var(--primary-color);
  margin-bottom: 8px; /* Adjusted margin for better spacing */
  font-weight: 500;
  line-height: 1.4;
}

.notification-user {
  font-size: 12px;
  color: var(--secondary-color);
  margin-top: 8px;
  margin-bottom: 0px;
  text-align: right;
}

.loading-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--secondary-color);
}

.no-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.no-notifications p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px; /* Slightly adjusted margin */
  color: var(--secondary-color);
}

.error-notification {
  background-color: #f8d7da;
  border-left: 4px solid #dc3545;
  box-shadow: 0 4px 6px rgba(220, 53, 69, 0.2); /* Shadow with a red tint */
}

.error-icon {
  color: #dc3545;
  margin-right: 8px; /* Space between the icon and message */
}
