.data-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.data-item {
  flex: 1 1 200px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px var(--box-shadow-color);
  background-color: var(--background-color);
  color: var(--primary-color);
}

.data-item.full-width {
  flex: 1 1 100%;
}

.data-item.half-width {
  flex: 1 1 50%;
}

.data-item.editable {
  outline: 1px dashed var(--secondary-color);
  background-color: var(--background-color);
}

.data-row {
  display: flex;
  flex-direction: column;
}

.data-row.full-width {
  width: 100%;
}

.table-container {
  overflow-x: auto;
}

.tabs-container {
  margin-top: 20px;
  width: 100%;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  background-color: var(--background-color);
  border-bottom: 2px solid #ccc;
}

.tab {
  flex: 1 1 200px;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px var(--box-shadow-color);
  background-color: var(--background-color);
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: none;
}

.tab.active {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.tab:hover, .tab:focus-visible {
  outline: 2px solid var(--secondary-color);
}

.tab-content {
  display: none;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.tab-content.active {
  display: block;
}

.editable-textarea {
  width: 100%;
  border: none;
  border-radius: 4px;
  background: none;
  outline: none;
  resize: none;
  overflow: hidden;
  padding: 5px;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  box-sizing: border-box;
}

textarea:focus {
  border-color: var(--primary-color);
  border-width: 1px;
  box-shadow: 0 0 5px var(--primary-color);
  overflow-y: visible;
}

.add-task-form {
  display: flex;
  flex-wrap: wrap;
}

.add-task-form .form-group-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.add-task-form .form-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(50% - 10px);
  margin: 5px;
}

.add-task-form label {
  font-size: inherit;
}

.add-task-form input {
  font-size: inherit;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.add-task-form .form-group-row:last-child .submit-button {
  flex: 1 1 100%;
  margin: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--secondary-color);
  background: none;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  transition: all 0.3s ease, border-color 0.3s ease, color 0.5s ease;
}

.add-task-form .form-group-row:last-child .submit-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.add-task-form .form-group-row:last-child .submit-button:hover:not(:disabled) {
  background-color: var(--primary-color);
  color: var(--navbar-background);
}

.add-task-wrapper {
  position: relative;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 1;
}

.form-overlay-message {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.small-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as necessary */
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rematch-controls-container {
  display: flex;
  flex-wrap: nowrap;  /* Prevents wrapping of items */
  justify-content: space-between;  /* Ensures even spacing between elements */
  margin-top: 15px;
  gap: 10px;  /* Consistent gap between items */
  padding: 20px;
}

.dropdown-wrapper {
  flex: 1 1 22%;  /* Allocates about 22% of the width to each dropdown */
}

.submit-buttons {
  flex: 1 1 22%;  /* Same width as the dropdowns */
  display: flex;
  gap: 0;  /* Removes gap between buttons */
  position: relative;  /* Enables the addition of dividers */
}

.ai-match-button, .task-rematch-button {
  background: none;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;  /* Keeps the border-radius */
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  color: var(--secondary-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  width: 100%;  /* Ensures the button takes up the full width */
  height: 3rem;
  white-space: nowrap;  /* Prevents text from wrapping */
  overflow: hidden;  /* Ensures the content doesn’t overflow the button */
  text-overflow: ellipsis;  /* Adds ellipsis for overflowing text */
}

.ai-match-button svg, .task-rematch-button svg {
  margin-left: 8px;
  flex-shrink: 0;  /* Prevents the icon from shrinking */
}

.ai-match-button:hover, .task-rematch-button:hover, 
.ai-match-button:focus-visible, .task-rematch-button:focus-visible {
  background-color: var(--primary-color);
  color: var(--navbar-background);
}

.submit-buttons .ai-match-button:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;  /* No border on the right side for the first button */
}

.submit-buttons .ai-match-button:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;  /* No border on the left side for the last button */
}

.submit-buttons::after {
  content: '';
  position: absolute;
  top: 30%;  /* Not full height */
  bottom: 30%;  /* Not full height */
  left: 50%;  /* Centers the divider between the buttons */
  width: 1px;
  background-color: var(--secondary-color);  /* Uses the secondary color for the divider */
  z-index: 1;  /* Ensures it's above the background but below the content */
}

.dropdown-wrapper .task-rematch-button {
  width: 100%;  /* Ensures the dropdown button takes up the full width */
}

.new-task::before {
  content: "NEW";
  background-color: #d4edda; /* Light green background */
  font-weight: bold;
  padding: 4px;
  border-radius: 4px;
  animation: fadeIn 0.5s ease-in-out;
}