.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 16px 0; /* Consistent spacing with modal */
}

.switch {
  position: relative;
  width: 60px; /* Adjusted width to accommodate labels */
  height: 24px; /* Slightly increased height for labels */
  background-color: var(--secondary-color);
  border-radius: 34px;
  transition: background-color 0.4s ease;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  border-radius: 34px;
  transition: 0.4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.switch-label-on,
.switch-label-off {
  position: absolute;
  font-size: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent labels from interfering with clicks */
  transition: opacity 0.4s;
}

.switch-label-on {
  left: 10px;
  color: white;
  opacity: 0;
}

.switch-label-off {
  right: 10px;
  color: white;
  opacity: 1;
}

.switch-input:checked + .switch-slider {
  background-color: var(--primary-color);
}

.switch-input:checked + .switch-slider:before {
  transform: translateX(36px); /* Adjust the transform to match the new width */
}

.switch-input:checked + .switch-slider .switch-label-on {
  opacity: 1; /* Show "On" label */
}

.switch-input:checked + .switch-slider .switch-label-off {
  opacity: 0; /* Hide "Off" label */
}

.switch:hover .switch-slider {
  box-shadow: 0 0 5px var(--box-shadow-color);
}

.switch-input:focus-visible + .switch-slider {
  outline: 2px solid var(--primary-color);
}
