/* Navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;600&display=swap');

.navbar {
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--navbar-background);
  padding: 15px 30px;
  box-shadow: 0 2px 4px var(--box-shadow-hover-color);
  transition: background-color 0.5s ease;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 20px; /* space between title and page info */
}

.navbar-title {
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.3px;

  cursor: pointer;
  user-select: none;
  transition: transform 0.3s ease, opacity 0.3s ease;

  /* Slight text shadow for subtle depth */
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.navbar-title:hover {
  transform: scale(1.03) translateY(-1px);
  opacity: 0.95;
}

.navbar-title:active {
  transform: scale(0.98) translateY(0);
  opacity: 1;
}

.navbar-page-info {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  opacity: 0.85;
}

.navbar-page-info::before {
  content: "•";
  color: var(--primary-color);
  font-size: 14px;
  margin-right: 8px;
  opacity: 0.7;
}

.navbar-actions {
  display: flex;
  gap: 16px;
}

.navbar-action,
.navbar-logout {
  color: var(--secondary-color);
  background: none;
  border: 1px solid var(--secondary-color);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.navbar-action:hover,
.navbar-logout:hover,
.navbar-action:active,
.navbar-logout:active,
.navbar-action:focus-visible,
.navbar-logout:focus-visible {
  background-color: var(--primary-color);
  color: var(--navbar-background);
}

.navbar-action > svg,
.navbar-logout > svg {
  font-size: 18px;
  transition: color 0.3s ease;
}

.navbar-action:hover > svg,
.navbar-logout:hover > svg,
.navbar-action:active > svg,
.navbar-logout:active > svg,
.navbar-action:focus-visible > svg,
.navbar-logout:focus-visible > svg {
  color: var(--navbar-background);
}

.icon-wrapper {
  transition: transform 0.5s ease-in-out;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.visible-button {
  display: flex;
}

.hidden-button {
  display: none;
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: var(--secondary-color);
}

.control-framework-dropdown {
  width: 20vw;
}

.hidden-input {
  display: none;
}