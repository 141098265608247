.description-match-container {
    background-color: var(--background-color);
    padding: 20px;
    padding-top: 0;
    height: 25%;
    display: grid;
    margin-bottom: 0;
    grid-template-rows: auto 1fr;
    box-shadow: 0px -2px 4px var(--box-shadow-hover-color);
    z-index: 5;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: height 0.15s ease;
}

.description-match-container.collapsed {
    height: 5%;
}

.description-match-header {
    background-color: var(--background-color);
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description-match-title {
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-match-content {
    overflow-y: auto;   
    grid-row: 2;
}

.description-match-input {
    /* Now we have four columns: 
       1) Description input 
       2) 'Choose Policies' button (example)
       3) 'Select your preset' dropdown 
       4) 'Send' button (example) 
    */
    display: grid;
    grid-template-columns: 1fr minmax(150px, auto) minmax(250px, auto) minmax(150px, auto);
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    z-index: 100;
    width: 100%;
}

.description-match-input input {
    margin-right: 10px;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    background-color: var(--navbar-background);
    border-radius: 5px;
    color: var(--primary-color);
}

.description-match-input input::placeholder {
    color: var(--primary-color);
    opacity: 0.8; 
}

/* If your Presets dropdown has its own class: */
.preset-dropdown {
    border: 1px solid var(--secondary-color);
    background-color: var(--navbar-background);
    border-radius: 5px;
    color: var(--primary-color);
    padding: 10px;
}

/* Buttons */
.description-match-button {
    background-color: var(--navbar-background);
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-size: 16px;
    cursor: pointer;
    color: var(--primary-color);
    transition: background-color 0.3s ease;
}

.description-match-button:disabled {
    background-color: var(--background-color);
    color: var(--secondary-color);
    cursor: not-allowed;
}

.description-match-button:not(:disabled):hover, .description-match-button:focus {
    background-color: var(--primary-color);
    color: var(--navbar-background);
}

.overlay {
    position: absolute;
    background-color: var(--background-color);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.return-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  color: var(--secondary-color);
}

.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  color: var(--secondary-color);
  margin-left: auto;
}
