.progress-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.progress-bar {
  position: relative;
  width: 90%;
  height: 14px;
  background-color: var(--background-color);
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid var(--primary-color);
}

.progress-bar-progress {
  height: 100%;
  background-color: var(--primary-color);
  width: 100%; /* This will cover the width of the progress */
  position: relative;
  border-radius: 5px;
  transition: width 0.5s ease-in-out; /* Animation for width */
}

.progress-bar-percentage {
  font-size: 14px;
  color: var(--primary-color);
  padding-left: 10px;
}

/* Striped effect */
.progress-bar-progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.05) 25%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.05) 75%,
    rgba(255, 255, 255, 0) 75%
  );
  background-size: 30px 30px;
  animation: move-stripes 3s linear infinite;
}

/* Animation for the stripes */
@keyframes move-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}
