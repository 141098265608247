.error-container {
    text-align: center;
    padding: 50px;
    color: var(--secondary-color);
  }
  
  .error-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: var(--primary-color);
  }
  
  .error-container p {
    font-size: 1.2em;
    color: var(--secondary-color);
  }
  
  .error-container svg {
    margin-bottom: 20px;
  }
  