/* ========================================
   Prompt Page Layout
======================================== */
.prompt-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.main-content {
  display: flex;
  flex: 1;
  flex-direction: row; /* Timeline, Prompt Sections, and Notification Center */
  height: calc(100vh - var(--navbar-height)); /* Adjust height for Navbar */
  overflow-y: hidden;
  padding: 20px;
}

/* ========================================
   Timeline Styling
======================================== */
.timeline-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--navbar-background);
  padding: 20px;
  box-shadow: 0 2px 4px var(--box-shadow-hover-color);
  border-radius: 4px;
  overflow-y: auto;
}

.timeline-item {
  display: flex;
  gap: 10px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 6px;
  margin-bottom: 10px;
}

.timeline-item:hover {
  background-color: var(--box-shadow-hover-color);
  transform: translateX(5px);
}

.timeline-item.active {
  background-color: var(--primary-color);
  color: var(--navbar-background);
  font-weight: bold;
}

.timeline-dot {
  width: 12px;
  height: 12px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  margin-top: 8px;
}

.timeline-item.active .timeline-dot {
  background-color: var(--navbar-background);
}

.timeline-content {
  flex: 1;
}

.timeline-prompt-name {
  margin: 0;
  font-size: 18px;
  color: var(--primary-color);
  font-weight: 600;
}

.timeline-prompt-meta {
  margin: 0;
  font-size: 14px;
  color: var(--secondary-color);
}

.timeline-category {
  font-weight: 500;
  color: var(--primary-color);
}

.timeline-item.active .timeline-category {
  color: var(--navbar-background);
}

.timeline-item.active .timeline-prompt-meta {
  color: var(--navbar-background);
}

.timeline-item.active .timeline-prompt-name {
  color: var(--navbar-background);
}

.timeline-date {
  font-style: italic;
}

/* ========================================
   Prompt Sections
======================================== */
.main-prompt-sections-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.prompt-sections-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 80%;
}

.prompt-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: var(--navbar-background);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px var(--box-shadow-color);
}

.prompt-section h2 {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 600;
}

/* ========================================
   Textarea Styling
======================================== */
.prompt-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  resize: none;
  background-color: var(--background-color);
  color: var(--primary-color);
}

/* ========================================
   Middle Section
======================================== */
.middle-section {
  flex: 0 0 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.middle-section p {
  font-size: 14px;
  color: var(--secondary-color);
  text-align: center;
}

.middle-section .arrow-icon {
  color: var(--secondary-color);
  font-size: 30px;
  transition: color 0.3s ease;
}

.middle-section .arrow-icon:hover {
  color: var(--primary-color);
}

/* ========================================
   Prompt Controls
======================================== */
.prompt-controls {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Three equal columns */
  grid-gap: 20px;
  align-items: center;
  justify-items: start;
  box-shadow: 0 2px 4px var(--box-shadow-color);
  background-color: var(--navbar-background);
  padding: 20px;
  border-radius: 6px;
}

/* Controls within grid */
.prompt-controls .switch-container,
.prompt-controls .dropdown-container,
.prompt-controls .input-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Dropdown Styling */
.prompt-controls .dropdown-container {
  justify-content: flex-start;
}

/* Input Fields for Exact Num and Min/Max */
.input-field {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 6px;
  font-size: 14px;
  color: var(--primary-color);
}

/* Save Button */
.prompt-page-container .save-button-container {
  grid-column: span 3; /* Make the button take the full width */
  display: flex;
  justify-content: flex-start;
}

.prompt-page-container .save-button {
  color: var(--primary-color);
  background: none;
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.prompt-page-container .save-button:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

/* ========================================
   Name Input Section
======================================== */
.name-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  background-color: var(--navbar-background);
  border-radius: 6px;
  box-shadow: 0 2px 4px var(--box-shadow-color);
}

.name-input-label {
  font-size: 14px;
  color: var(--primary-color);
  margin-bottom: 5px;
  font-weight: bold;
}

.name-input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 14px;
  color: var(--primary-color);
  background-color: var(--background-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.name-input-field:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 4px var(--primary-color);
}

.error-message {
  color: #d8000c; /* A bold red color for the text */
  background-color: #ffd2d2; /* A soft red/pinkish background to indicate error */
  font-size: 14px;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #d8000c;
  border-radius: 4px;
  font-weight: bold;
}

.error-message::before {
  content: "⚠ "; /* A warning symbol to catch the user's attention */
}
