.table-wrapper {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
}

.table-spinner {
  margin: auto;
  padding-top: 40%;
}

.table-scroll-wrapper {
    overflow-x: scroll;
}

.statistics-section {
  padding: 20px 0px;
  margin-top: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
}

.statistics-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: var(--secondary-color);
  font-weight: bold;
}

.statistics-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.statistics-item {
  flex: 1 1 calc(25% - 15px); /* Three items per row */
  padding: 15px;
  border-radius: 6px;
  background-color: var(--background-color); /* Using a slightly different shade */
  color: var(--primary-color);
  text-align: center;
  box-shadow: 0 1px 3px var(--box-shadow-color);
  cursor: pointer; /* Indicate that item is clickable */
  transition: background-color 0.3s, box-shadow 0.3s;
}

.statistics-item:hover {
  background-color: var(--navbar-background); /* Change background color on hover */
  box-shadow: 0 4px 6px var(--box-shadow-hover-color);
}

.statistics-item strong {
  display: block;
  margin-bottom: 5px;
}

.no-file-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.no-file-found h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary-color);
}

.no-file-found p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px; /* Slightly adjusted margin */
  color: var(--secondary-color);
}

.header-button {
  position: relative; /* Ensure the button is positioned relative to the cell */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copy-button {
  background: transparent;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  transition: all 0.3s;
  border-radius: 4px;
  font-size: 18px;
  margin-left: 10px;
}

.copy-button:not(:focus-visible) {
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially not visible */
}

.header-button:hover .copy-button {
  opacity: 1; /* Show button on hover */
  visibility: visible; /* Make it visible on hover */
}

.header-button:focus-visible .copy-button {
  opacity: 1; /* Show button on focus */
  visibility: visible; /* Make it visible on focus */
}

.copy-button:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}
