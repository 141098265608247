:root {
  --slide-duration: 0.15s;
}

.dropdown-container {
  position: relative;
}

.dropdown-container:not(.dropdown-not-inline) {
  display: inline-block;
}

.dropdown-button {
  background-color: var(--background-color);
  color: var(--primary-color);
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transition for background and text color */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  background-color: var(--background-color);
  border-radius: 4px;
  box-shadow: 0 2px 10px var(--box-shadow-color);
  width: min(min-content, 10vw);
  transition: max-height var(--slide-duration) ease-in-out, opacity var(--slide-duration) ease-in-out, background-color 0.3s ease;
  width: 100%;
}

.dropdown-list.close {
  max-height: 0;
  overflow-y: hidden;
  opacity: 0;
}

.dropdown-list.open {
  max-height: 20vh;
  overflow-y: scroll;
  opacity: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.dropdown-item:hover {
  padding: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.dropdown-item-content {
  display: flex;
  align-items: center;
}

.checkbox-icon {
  margin-right: 8px;
}

.dropdown-item.with-border {
  border-bottom: 1px solid var(--background-color);
}

.dropdown-list.up {
  bottom: 100%; 
}

.dropdown-item.select-clear-buttons {
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  cursor: auto;
}

.dropdown-item.select-clear-buttons.multiple {
  justify-content: space-around;
  padding: 7px 0;
}

.dropdown-action-button {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  padding: 2px; /* Remove padding */
  border-radius: 4px;
  text-decoration: none; /* Remove underline */
  margin: 0; /* Remove margin */
}

.dropdown-action-button:hover, .dropdown-action-button:focus-visible {
  text-decoration: underline; /* Add underline on hover */
  background-color: none;
  color: var(--primary-color); /* Optional: Darken the color on hover */
}

.dropdown-divider {
  border-bottom: 1px solid var(--primary-color); /* Subtle divider color */
  margin-bottom: 5px;
  opacity: 0.2;
}

.dropdown-divider-vertical {
  border-right: 1px solid var(--secondary-color); /* Subtle divider color */
  opacity: 0.2;
}
